import { Table, Badge, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { getAllJgTeams, createJgTeams } from '../../services/api';
import { JgTeam } from '../../entities/JgTeam';

export default function JgTeams() {
  const { t } = useTranslation('common');
  const [teams, setTeams] = useState<JgTeam[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTeamNames, setSelectedTeamNames] = useState<string[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [teamsToCreate, setTeamsToCreate] = useState<string[]>([]);
  const [editingTeamName, setEditingTeamName] = useState<string | null>(null);
  const [tempTeamName, setTempTeamName] = useState<string>('');
  const [filter, setFilter] = useState({
    status: '',
    year: ''
  });
  const [filteredTeams, setFilteredTeams] = useState<JgTeam[]>([]);

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const data = await getAllJgTeams();
      setTeams(data);
      setFilteredTeams(data);
      setError(null);
    } catch (err) {
      setError('Fehler beim Laden der Teams');
      console.error('Error fetching teams:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    // Filter teams based on selected filters
    let result = [...teams];
    
    if (filter.status) {
      result = result.filter(team => team.status === filter.status);
    }
    
    if (filter.year) {
      result = result.filter(team => {
        return getYearFromTeamName(team.name) === filter.year;
      });
    }
    
    setFilteredTeams(result);
  }, [filter, teams]);

  const handleCreate = async (teamName: string) => {
    setTeamsToCreate([teamName]);
    setShowConfirmDialog(true);
  };

  const handleCreateSelectedTeams = () => {
    setTeamsToCreate(selectedTeamNames);
    setShowConfirmDialog(true);
  };

  const handleConfirmCreate = async () => {
    try {
      await createJgTeams(teamsToCreate);
      await fetchTeams();
      setSelectedTeamNames([]);
      setError(null);
      setShowConfirmDialog(false);
    } catch (err) {
      setError('Fehler beim Erstellen der Teams');
      console.error('Error creating teams:', err);
    }
  };

  const getYearFromTeamName = (teamName: string): string => {
    return teamName.slice(-4);
  };

  const getConfirmationMessage = (): string => {
    const years = Array.from(new Set(teamsToCreate.map(getYearFromTeamName)));
    const yearsList = years.join(', ');
    
    const teamsList = teamsToCreate
      .map(team => `• ${team}`)
      .join('\n');
    
    return `Achtung:
Nach dem Anlegen der Teams-Jahrgangsgruppe werden Sie als BesitzerIn und die Studierenden des Jahrgangs ${yearsList} (Jahr der Imatrikulation) direkt in den Kurs eingeschrieben und per Mail informiert. Studierende eines anderen Jahrgangs, die aufgrund der flexiblen Lehre auch Zugang zu diesem Jahrgangskurs brauchen, müssen direkt in der Teams-Gruppe manuell von dem/der BesitzerIn eingeschrieben und informiert werden.

Folgende Teams werden angelegt:
${teamsList}

Sind Sie sicher das der/die ausgewählten Gruppen jetzt angelegt werden sollen:`;
  };
  const handleCheckboxChange = (teamName: string) => {
    setSelectedTeamNames(prevSelected => {
      if (prevSelected.includes(teamName)) {
        return prevSelected.filter(name => name !== teamName);
      } else {
        return [...prevSelected, teamName];
      }
    });
  };

  const tableRowStyle = {
    height: '56px'
  };

  const tableCellStyle = {
    verticalAlign: 'middle' as const
  };

  // Get unique years from team creation dates
  const getAvailableYears = () => {
    const years = teams.map(team => getYearFromTeamName(team.name));
    return Array.from(new Set(years)).sort();
  };

  if (loading) {
    return <div>Lade Teams...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <div>
      <h2 className="mb-4">JG-Kurse beantragen</h2>
      
      <div className="d-flex gap-2 align-items-center mb-3">
        <span>{selectedTeamNames.length} Datensätze gewählt</span>
        <Button 
          variant="primary"
          onClick={handleCreateSelectedTeams}
          disabled={selectedTeamNames.length === 0}
        >
          Teams anlegen
        </Button>
      </div>

      <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-3 border-bottom">
        <span>Status: </span>
        <Form.Select
          value={filter.status}
          className="ms-3"
          style={{ width: '200px' }}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        >
          <option value="">Alle</option>
          <option value="offen">Offen</option>
          <option value="bereits angelegt">Bereits angelegt</option>
        </Form.Select>
        
        <span className="ms-3">Jahr: </span>
        <Form.Select
          value={filter.year}
          className="ms-3"
          style={{ width: '150px' }}
          onChange={(e) => setFilter({ ...filter, year: e.target.value })}
        >
          <option value="">Alle</option>
          {getAvailableYears().map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </Form.Select>
      </div>

      <Table className="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Ersteller</th>
            <th scope="col">Status</th>
            <th scope="col">Team Name</th>
            <th scope="col">Erstellungsdatum</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {filteredTeams.map((team) => (
            <tr key={team.name} style={tableRowStyle}>
              <td style={tableCellStyle}>
                <Form.Check
                  type="checkbox"
                  checked={selectedTeamNames.includes(team.name)}
                  onChange={() => handleCheckboxChange(team.name)}
                  disabled={team.status === 'bereits angelegt'}
                />
              </td>
              <td style={tableCellStyle}>{team.createdBy}</td>
              <td style={tableCellStyle}>
                <Badge bg={team.status === 'offen' ? 'primary' : 'success'}>
                  {team.status}
                </Badge>
              </td>
              <td style={tableCellStyle}>
              <div className="d-flex gap-2 align-items-center">
                    <span>{team.name}</span>
                  </div>
              </td>
              <td style={tableCellStyle}>{team.createdAt}</td>
              <td style={tableCellStyle}>
                {team.status === 'offen' && (
                  <Button 
                    variant="primary" 
                    size="sm"
                    onClick={() => handleCreate(team.name)}
                  >
                    Anlegen
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Teams erstellen bestätigen</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line' }}>
          {getConfirmationMessage()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDialog(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleConfirmCreate}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
} 
import { Table, Badge, Form, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { getAllManualTeams, createManualTeam } from '../../services/api';
import { ManualTeam } from '../../entities/ManualTeam';
import { UserContext } from "../../userContext";

export default function TeamsManual() {
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const [teams, setTeams] = useState<ManualTeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<ManualTeam[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [teamToCreate, setTeamToCreate] = useState<ManualTeam | null>(null);
  const [filter, setFilter] = useState({
    status: '',
    year: ''
  });

  // Form state for new team
  const [newTeam, setNewTeam] = useState<ManualTeam>({
    name: '',
    createdBy: user?.firstname + " " + user?.lastname,
    status: 'Neu',
    teamType: '',
    createdAt: null
  });

  const teamTypes = [
    'STUD',
    'EVENT',
    'COURSE',
    'TEAM',
    'PROJECT',
    'STG',
    'JG'
  ];

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const data = await getAllManualTeams();
      setTeams(data);
      setFilteredTeams(data);
      setError(null);
    } catch (err) {
      setError('Fehler beim Laden der Teams');
      console.error('Error fetching teams:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  // Apply filters
  useEffect(() => {
    let result = [...teams];
    
    if (filter.status) {
      result = result.filter(team => team.status === filter.status);
    }
    
    if (filter.year) {
      result = result.filter(team => {
        // Ignore teams with null createdAt
        if (!team.createdAt) return false;
        
        // Parse date in format DD.MM.YYYY
        const year = parseYearFromDate(team.createdAt);
        return year === filter.year;
      });
    }
    
    setFilteredTeams(result);
  }, [teams, filter]);

  // Parse year from date string in format DD.MM.YYYY
  const parseYearFromDate = (dateString: string): string => {
    // Check if the date is in format DD.MM.YYYY
    if (dateString.includes('.')) {
      const parts = dateString.split('.');
      if (parts.length === 3) {
        return parts[2]; // Return the year part
      }
    }
    
    // Fallback to standard date parsing
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? date.getFullYear().toString() : 'Unbekannt';
  };

  // Get unique years from team creation dates
  const getAvailableYears = () => {
    const years = teams
      .filter(team => team.createdAt !== null) // Filter out teams with null createdAt
      .map(team => parseYearFromDate(team.createdAt as string))
      .filter(year => year !== 'Unbekannt'); // Filter out unknown years
    
    return Array.from(new Set(years)).sort();
  };

  const handleCreate = async (team: ManualTeam) => {
    setTeamToCreate(team);
    setShowConfirmDialog(true);
  };

  const handleConfirmCreate = async () => {
    if (!teamToCreate) return;

    try {
      await createManualTeam(teamToCreate);
      await fetchTeams();
      setError(null);
      setShowConfirmDialog(false);
      // Reset new team form
      setNewTeam({
        name: '',
        createdBy: user?.firstname + " " + user?.lastname,
        status: 'Neu',
        teamType: '',
        createdAt: null
      });
    } catch (err) {
      setError('Fehler beim Erstellen des Teams');
      console.error('Error creating team:', err);
    }
  };

  const getConfirmationMessage = (): string => {
    return `Achtung:
Nach dem Anlegen des manuellen Teams werden Sie als BesitzerIn direkt in den Kurs eingeschrieben und per Mail informiert. Weitere BesitzerInnen und MitgliederInnen müssen manuell in der Teamsgruppe hinzugefügt werden.
Sind sie sicher das die ausgewählte Gruppe jetzt angelegt werden soll:`;
  };

  const tableRowStyle = {
    height: '56px'
  };

  const tableCellStyle = {
    verticalAlign: 'middle' as const
  };

  if (loading) {
    return <div>Lade Teams...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <div>
      <h2 className="mb-4">Manuelle Kurse beantragen</h2>

      <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-3 border-bottom">
        <span>Status: </span>
        <Form.Select
          value={filter.status}
          className="ms-3"
          style={{ width: '200px' }}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        >
          <option value="">Alle</option>
          <option value="Neu">Neu</option>
          <option value="Bereits angelegt">Bereits angelegt</option>
        </Form.Select>
        
        <span className="ms-3">Jahr: </span>
        <Form.Select
          value={filter.year}
          className="ms-3"
          style={{ width: '150px' }}
          onChange={(e) => setFilter({ ...filter, year: e.target.value })}
        >
          <option value="">Alle</option>
          {getAvailableYears().map(year => (
            <option key={year} value={year}>{year}</option>
          ))}
        </Form.Select>
      </div>

      <Table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Teams-Art</th>
            <th scope="col">Status</th>
            <th scope="col">Team Name</th>
            <th scope="col">Erstellungsdatum</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {/* New team form row */}
          <tr style={tableRowStyle}>
            <td style={tableCellStyle}>{newTeam.createdBy}</td>
            <td style={tableCellStyle}>
              <Form.Select
                value={newTeam.teamType}
                onChange={(e) => setNewTeam({ ...newTeam, teamType: e.target.value })}
                style={{ width: '150px' }}
              >
                <option value="">Auswählen...</option>
                {teamTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Select>
            </td>
            <td style={tableCellStyle}>
              <Badge bg="primary">Neu</Badge>
            </td>
            <td style={tableCellStyle}>
              <Form.Control
                type="text"
                value={newTeam.name}
                onChange={(e) => setNewTeam({ ...newTeam, name: e.target.value })}
                placeholder="Team Name eingeben"
              />
            </td>
            <td style={tableCellStyle}></td>
            <td style={tableCellStyle}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => handleCreate(newTeam)}
                disabled={!newTeam.name || !newTeam.teamType}
              >
                Anlegen
              </Button>
            </td>
          </tr>

          {/* Existing teams */}
          {filteredTeams.map((team) => (
            <tr key={team.name} style={tableRowStyle}>
              <td style={tableCellStyle}>{team.createdBy}</td>
              <td style={tableCellStyle}>{team.teamType}</td>
              <td style={tableCellStyle}>
                <Badge bg={team.status === 'Neu' ? 'primary' : 'success'}>
                  {team.status}
                </Badge>
              </td>
              <td style={tableCellStyle}>{team.name}</td>
              <td style={tableCellStyle}>{team.createdAt}</td>
              <td style={tableCellStyle}>
                {team.status === 'Neu' && (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleCreate(team)}
                  >
                    Anlegen
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Team erstellen bestätigen</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ whiteSpace: 'pre-line' }}>
          {getConfirmationMessage()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDialog(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleConfirmCreate}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
